.yahoo_add_symbol_floating_container { position: fixed; top: 50px; left: 50px; min-width: 75%; min-height: 100px; padding: 16px;
                                       background-color: white; border-radius: 2px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); z-index: 1000; }

.yahoo_isin_lookup_results_container_div { display:flex;flex-direction:column;border-radius:20px;border:rgba(0, 0, 0, 0.164) solid 1px;overflow: hidden; }

.yahoo_results_row_container_div { display:flex; flex-direction:row; flex-wrap:wrap; justify-content:space-between; align-items:flex-start; align-content:flex-start; gap:4px; }
.yahoo_results_row { margin:0;width:100%;display:flex; flex-direction:row; flex-wrap:wrap; justify-content:space-between;align-items:center; align-content:center; }
.yahoo_results_plus_btn_col { width:2%;cursor:pointer; }
.yahoo_results_plus_btn_div { width:22px;height:22px;border-radius:11px;border:#AAA solid 1px;background-color:#999;color:#EEE;text-align:center;font-size:12px;font-weight:bold;}
.yahoo_results_symbol_col { width:20%;font-size:14px; }
.yahoo_results_exchange_col { width:20%;font-size:14px; }
.yahoo_results_name_col { width:50%;font-size:14px; }
.yahoo_results_row_container_odd { background-color:lightgray; }
.yahoo_results_row_container_even { background-color:whitesmoke; }
