.settings-ui-container { 
    background-color:aliceblue;
    padding:16px;
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    gap: 10px;
}

.spinner-div-width {
    width: 100px;
    text-align:center;
}

.settings-ui-dropdown-toggle {
    min-width:200px;
}

.settings-ui-error-div {
    background-color: lightcoral;
    text-align:center;
}

.settings-ui-error-p {
    color:crimson;
    padding: 16px;
}

.regression-offset-controls-container {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}


.regression-control-btn-container {
    padding:0;
    margin:0 8px;
}

.regression-control-btn {
    color:#ffffff;
    background-color: #408558;
	border-radius:5px;
	display:inline-block;
	cursor:pointer;
	font-size:10px;
	padding:2px 12px;
    margin:0;
	text-decoration:none;
}

.regression-control-btn:hover {
	color:#ffffff;
    font-weight: bold;
}
.regression-control-btn.myButton:active {
	color:#ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.regression-offset-amount-input {
    text-align: right !important; 
    margin:0;
    width:80px;
    padding: 5px;
    font-size: 16px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #333;
    border-style: solid;
    border-radius: 4px;
}

.regression-offset-amount-input:focus {
    outline:none;
}